<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
    
		<div id="container">
			<ion-list>
				<ion-item class="ion-no-padding" v-for="item in messages" v-bind:key='item.id'>
					<div class="post">
						<div class="post__header">
							<span class="post__date">{{item.created_at}}</span><span v-if="item.is_read===0">не прочитано</span>
						</div>
						<div class="post__body">
							<div class="post__author" @click="() => $router.push(getUserPath(item.user))">
								<img v-bind:src="getUserAvatar(item.user)">
								<div class="post__username">{{item.user.nickname}}</div>
								<div class="post__sign">{{item.user.sign}}</div>
							</div>
							<div class="post__text selectable">
								<div class="post__text_content" v-html="getRichText(item.message)"></div>
								<div class="fast-quote" @click="getQuote">> Quote</div>
							</div>
						</div>
					</div>
				</ion-item>
				
			</ion-list>
			
		</div>		
		<MessageField v-bind:sendpoint="sendpoint" v-bind:passquote="passquote"  @msg-sended="getNew"></MessageField>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
.message-form {
	position: fixed;
	bottom: -20px;
	width: 100%;
	border: 1px solid blue;
}
#container {
	padding-bottom: 50px;
}
.fast-quote {
	margin-top: 25px;
	color: #808080;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import MessageField from '@/views/MessageField.vue';

export default {
	name: 'Dialog',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		MessageField
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Inbox');
    const matchedFolder = computed(() => route.params.id);
    let chkTimer: number;
	const { token, getRichText } = useDataService();
	
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
	
	watch(route, () => {
		clearTimeout(chkTimer);
	});
	
	const messages = ref<any>([]);
	let gettingNew = false;
	
	const playOk = function(){
		const audio = new Audio('/sounds/notify.wav');
		audio.play();
	};
	
	const getNew = function(){
		if(!gettingNew){
			gettingNew = true;
			const lastmsg = messages.value[messages.value.length-1].id;
			axios.get('https://beon.fun/api/v1/im/'+route.params.usr+'/get?last='+lastmsg,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then(response => (
				response.data.forEach((item: any) => {messages.value.push(item)}),
				playOk()
			)).finally(() => (gettingNew = false));
		}
	};
	
	const checkNew = function(){
		const lastmsg = messages.value[messages.value.length-1].id;
		axios.get('https://beon.fun/api/v1/im/'+route.params.usr+'/check?last='+lastmsg,{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(response.data.new == 1){
				getNew();
			}
		});
	};
	const sendpoint = 'https://beon.fun/api/v1/im/'+route.params.usr;
	
	axios.get('https://beon.fun/api/v1/im/'+route.params.usr,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
		response.data.messages.forEach((item: any) => {messages.value.push(item)}),
		folder.value = response.data.recipient.nickname,
		chkTimer = setInterval(checkNew, 5000)
	));
	const passquote = ref<string>("");
	const getQuote = function(e: any){
		passquote.value = "\r\n" + e.currentTarget.parentNode.childNodes[0].innerText.replace(/^/g,'> ').replace(/\n/g,'\n> ');
	};
	
    return { folder, messages, sendpoint, getNew, getQuote, passquote, getRichText }
  }
}
</script>