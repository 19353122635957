
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonList } from '@ionic/vue';
import { useRoute } from 'vue-router';
import { ref, computed, watch } from 'vue';
import axios from 'axios';
import useDataService from "../dataservice";
import MessageField from '@/views/MessageField.vue';

export default {
	name: 'Dialog',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonList,
		MessageField
	},
	methods: {
		getUserPath: function(user: any){
			return "/user/" + user.name;
		},
		getUserAvatar: function(user: any){
			return user.avatar ? "//i0.beon.fun/" + user.path + "/avatars/" + user.avatar.idx + "." + user.avatar.ext : '';
		}
	},
  setup() {
    const route = useRoute();
    const folder = ref(route.params.id || 'Inbox');
    const matchedFolder = computed(() => route.params.id);
    let chkTimer: number;
	const { token, getRichText } = useDataService();
	
    watch(matchedFolder, () => {
      folder.value = matchedFolder.value as string;
    });
	
	watch(route, () => {
		clearTimeout(chkTimer);
	});
	
	const messages = ref<any>([]);
	let gettingNew = false;
	
	const playOk = function(){
		const audio = new Audio('/sounds/notify.wav');
		audio.play();
	};
	
	const getNew = function(){
		if(!gettingNew){
			gettingNew = true;
			const lastmsg = messages.value[messages.value.length-1].id;
			axios.get('https://beon.fun/api/v1/im/'+route.params.usr+'/get?last='+lastmsg,{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then(response => (
				response.data.forEach((item: any) => {messages.value.push(item)}),
				playOk()
			)).finally(() => (gettingNew = false));
		}
	};
	
	const checkNew = function(){
		const lastmsg = messages.value[messages.value.length-1].id;
		axios.get('https://beon.fun/api/v1/im/'+route.params.usr+'/check?last='+lastmsg,{
		headers: {
			Authorization: 'Bearer ' + token.value,
		}})
		.then((response) => {
			if(response.data.new == 1){
				getNew();
			}
		});
	};
	const sendpoint = 'https://beon.fun/api/v1/im/'+route.params.usr;
	
	axios.get('https://beon.fun/api/v1/im/'+route.params.usr,{
	headers: {
		Authorization: 'Bearer ' + token.value,
	}})
    .then(response => (
		response.data.messages.forEach((item: any) => {messages.value.push(item)}),
		folder.value = response.data.recipient.nickname,
		chkTimer = setInterval(checkNew, 5000)
	));
	const passquote = ref<string>("");
	const getQuote = function(e: any){
		passquote.value = "\r\n" + e.currentTarget.parentNode.childNodes[0].innerText.replace(/^/g,'> ').replace(/\n/g,'\n> ');
	};
	
    return { folder, messages, sendpoint, getNew, getQuote, passquote, getRichText }
  }
}
